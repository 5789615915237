<template>
    <div>
        <div v-if="!hasCurrentLocation" class="no-location-selected"><span class="fa fa-map-marker"></span>Enter Your Location Above</div>
        <div v-if="loaded && hasCurrentLocation && locations.length == 0" class="no-location-selected"><span class="fal fa-frown"></span>{{ failed ? 'Unable to load shops' : 'No Open Shops Available' }}</div>
		<div v-if="!loaded && hasCurrentLocation && locations.length == 0" class="loading-curtain active">
			<div class="container"><img src="/images/logo.png" alt=""></div>
		</div>
        <div v-if="hasCurrentLocation && locations.length > 0" class="browse-locations">
            <router-link v-for="location in locations" :key="`location-${ location.id }`" :to="`/browse/${ location.slug }/locations/${ location.id }/items`">
                <div class="top">
                    <div class="title">{{ location.name || location.business.name }}</div>
                    <div class="stars"><span class="fa fa-star"></span>{{ location.rating ? (location.rating / 2).toFixed(1) : '5.0' }}</div>
                </div>
                <div class="photo"><img :src="location.banner_image_url || '/images/photos/coffee.jpg'"><div class="distance">{{ distance(location) }}</div></div>
            </router-link>
        </div>
    </div>
</template>

<script>

import _ from 'underscore';
import { mapGetters } from 'vuex';
import { friendlyDistance } from '@/utils/distance';

export default {

	name: 'BrowseLocationsByListPage',

	data() {
		return {
			initialized: false,
			loaded: false,
			failed: false,
			items: [],
		};
	},

	computed: {

		...mapGetters([ 'hasCurrentLocation', 'currentLocation' ]),

		locations() {
			return _.sortBy(this.items, 'distance');
		},
	},

	mounted() {
		this.addRootListener('location-changed', () => this.fetchLocationsByDistance());
		this.fetchLocationsByDistance();
	},

	methods: {

		distance: friendlyDistance,

		async fetchLocationsByDistance() {
			await this.fetchLocations(this.$api.post('/browse/by-location', {
				latitude: this.currentLocation.latitude,
				longitude: this.currentLocation.longitude,
			}));
		},

		async fetchLocations(request) {
			this.loaded = false;
			this.failed = false;

			try {
				const { data } = await request;

				this.items = data.data;
			}
			catch (e) {
				this.failed = true;
				this.$store.dispatch('errorToast', 'Unable to load search results');
			}

			this.loaded = true;
		}


	},

};

</script>
