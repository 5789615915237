export function friendlyDistance(location) {
	const suffixes = {
		miles: { singular: 'mile', plural: 'miles' },
		feet: { singular: 'foot', plural: 'feet' },
	};

	let distance = Math.floor(location.distance * 10) / 10;
	let suffix = suffixes.miles;

	if (distance <= 0.1) {
		distance = Math.floor(location.distance * 5280);
		suffix = suffixes.feet;
	}

	return `${ distance } ${ suffix[distance === 1 ? 'singular' : 'plural' ] }`;
}
