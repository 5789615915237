<template>
	<div class="main-browser-container">
        <div v-if="!hasCurrentLocation" class="no-location-selected"><span class="fal fa-arrow-up"></span>Please Select A Location</div>
		<div v-if="hasCurrentLocation">
			<GmapMap
				:options="mapOptions"
				:center="center"
				:zoom="zoom"
				class="browse-map"
				ref="map"
				@bounds_changed="boundsChanged"
			>
				<gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false"></gmap-info-window>

				<GmapMarker
					:key="`marker-${ marker.location.id }`"
					:ref="`marker-${ marker.location.id }`"
					v-for="marker in locationMarkers"
					:position="marker.position"
					:clickable="true"
					:draggable="false"
					:info-text="marker.location.name || marker.location.business.name"
					:icon="marker.icon"
					@click="() => onMarkerClick(marker)"
					@close="() => selected = {}"
					@closeclick="() => selected = {}"
					@close_click="() => selected = {}"
				/>

				<GmapMarker
					key="current-location"
					:position="{ lat: currentLocation.latitude, lng: currentLocation.longitude }"
					:icon="currentLocationMarkerIcon"
					:clickable="false"
					:draggable="false"
					title="You"
				/>
			</GmapMap>
		</div>
	</div>
</template>

<script>

import { gmapApi } from 'vue2-google-maps';
import _ from 'underscore';
import { mapGetters } from 'vuex';
import { friendlyDistance } from '@/utils/distance';
import mapOptions from '@/utils/maps';

const boundsChanged = _.debounce((bounds, vm) => {
	let zoom = 11;
	if (vm.$refs && vm.$refs.map) {
		zoom = vm.$refs.map.$mapObject.zoom;
	}

	const center = bounds.getCenter();
	const lat = center.lat();
	const lng = center.lng();
	if (process.client && (vm.$route.query.lat != lat || vm.$route.query.lng != lng || vm.$route.query.zoom != zoom)) {
		vm.$router.replace({ path: vm.$route.path, query: { lat, lng, zoom } });
	}

	vm.fetchLocationsByBounds(bounds);
}, 400);

export default {

	name: 'BrowseLocationsPage',

	data() {
		return {
			queryCenter: {
				lat: this.$route.query.lat,
				lng: this.$route.query.lng,
			},
			zoom: parseInt(this.$route.query.zoom || 11, 10),
			initialized: false,
			loaded: false,
			failed: false,
			items: [],
			selected: {},
			infoWindowPos: null,
			infoWinOpen: false,
			mapOptions,

			infoOptions: {
				content: '',
				//optional: offset infowindow so it visually sits nicely on top of our marker
				pixelOffset: {
					width: 0,
					height: -35
				}
			},
		};
	},

	computed: {

		google: gmapApi,

		...mapGetters([ 'hasCurrentLocation', 'currentLocation' ]),

		center() {
			return {
				lat: parseFloat(this.queryCenter.lat || this.currentLocation.latitude),
				lng: parseFloat(this.queryCenter.lng || this.currentLocation.longitude),
			};
		},

		locations() {
			return _.sortBy(this.items, 'distance');
		},

		locationMarkers() {
			if (!this.google) return [];

			return this.locations.map(location => {
				return {
					position: new this.google.maps.LatLng(location.latitude, location.longitude),
					icon: {
						path: this.google.maps.SymbolPath.CIRCLE,
						scale: 10,
						fillColor: '#FFF',
						fillOpacity: 1,
						strokeColor: '#3abeca'
					},
					location
				};
			});
		},

		currentLocationMarkerIcon() {
			if (!this.google) return undefined;

			return new this.google.maps.MarkerImage('//maps.gstatic.com/mapfiles/mobile/mobileimgs2.png',
				new this.google.maps.Size(22,22),
				new this.google.maps.Point(0,18),
				new this.google.maps.Point(11,11)
			);
		},

	},

	mounted() {
		this.addRootListener('location-changed', (location) => this.handleLocationChanged(location));

		boundsChanged.bind(this);
	},

	methods: {

		boundsChanged(bounds)  {
			boundsChanged(bounds, this);
		},

		onMarkerClick(marker) {
			this.infoWindowPos = marker.position;
			// this.infoOptions.content = marker.location.name || marker.location.business.name;
			this.infoOptions.content = `<a href="/browse/${ marker.location.slug }/locations/${ marker.location.id }/items" class="map-popup"><div class="photo" style="background:url('${ marker.location.banner_image_url || '/images/photos/coffee.jpg'}') center center no-repeat; background-size:cover;"></div><div class="name">${ marker.location.name || marker.location.business.name }</div><div class="ready"><span class="tag"><span class="fa fa-clock" aria-hidden="true"></span> Ready within ${ Math.max(2, marker.location.ready_in_max) } minutes</span></div></a>`;

			//check if its the same marker that was selected if yes toggle
			if (this.selected && this.selected.id == marker.location.id) {
				this.infoWinOpen = !this.infoWinOpen;
				this.selected = {};
			}
			else {
				this.infoWinOpen = true;
				this.selected = marker.location;
			}

		},

		distance: friendlyDistance,

		async handleLocationChanged(location) {
			this.queryCenter = {
				lat: location.latitude,
				lng: location.longitude,
			};
		},

		async fetchLocationsByBounds(bounds) {
			const ne = bounds.getNorthEast();
			const sw = bounds.getSouthWest();

			await this.fetchLocations(await this.$api.post('/browse/by-location', {
				bounds: {
					ne: {
						lat: ne.lat(),
						lng: ne.lng(),
					},
					sw: {
						lat: sw.lat(),
						lng: sw.lng(),
					},
				},
				latitude: this.currentLocation.latitude,
				longitude: this.currentLocation.longitude,
			}));
		},

		async fetchLocations(request) {
			this.loaded = false;
			this.failed = false;

			try {
				const { data } = await request;

				this.items = data.data;
			}
			catch (e) {
				this.failed = true;
				this.$store.dispatch('errorToast', 'Unable to load search results');
			}

			this.loaded = true;
		}


	},

};

</script>
