<template>
	<no-ssr>
		<component :is="browseComponent" v-bind="$props"></component>
	</no-ssr>
</template>

<script>

import BrowseByMap from './BrowseByMap';
import BrowseByList from './BrowseByList';

export default {

	name: 'BrowseLocationsWrapperPage',

	computed: {
		browseComponent() {
			return this.$route.name === 'browse-by-map' ? BrowseByMap : BrowseByList;
		},
	},

};

</script>
